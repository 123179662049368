.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
}

.App-Basic {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex: 1;
}
 

.grid-container {
  display: flex;
  height: 100%;
}

.grid-inner {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  align-items: flex-end;
  justify-content: flex-end;
}



.grid-inner:hover {
  filter: grayscale(0%);
  cursor: pointer;
}

.grid-inner:hover > h1 {
  scale: 1.1;
  color: #61dafb;
}

.big-Text {
  font-family: 'Coda', cursive;
  font-size: calc(10px + 4vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
  margin: 10px;
}

.big-Text:hover {
  color: #61dafb;
  scale: 1.1;
}

.small-Text {
  font-family: 'Coda', cursive;
  display: flex;
  flex: 1;
  font-size: calc(5px + 2vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
  align-items: center;
  justify-content: center;
}

.centered {
  text-align: center;
}

.centered > h1 {
  font-family: 'Coda', cursive;
  font-size: calc(10px + 2vmin);
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 10px #000;
}

.loud {
  font-family: 'Coda', cursive;
  color: rgb(95, 244, 255);
  text-shadow: 0 0 10px #000;
  scale: 1.1;
}


.image-list {
  display: flex;
  flex: 1;
  margin-left: 25%;
  margin-right: 25%;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
}


.normal-Text {
  font-family: 'Coda', cursive;
  display: flex;
  flex: 1;
  font-size: calc(10px + 4vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
  margin: 10px;
  justify-content: center;
  align-items: center;
}

.back-button {
  display: flex;
  flex: 1;
  font-size: large;
  color: #fff;
  flex-direction: column;
  align-items: left;
}

.white-home {
  display: flex;
  flex: 1;
  color: #fff;
}

.white-home:hover {
  scale: 1.1;
  color: #61dafb;
  max-width: 90%;
}


.stack-container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
}


.aboutText {
  font-family: 'Coda', cursive;
  font-size: calc(10px + 4vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
}

.aboutTextSmall {
  font-family: 'Coda', cursive;
  font-size: calc(5px + 2vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
  text-align: center;
}

.center-on-screen {
  display: flex;
  flex: 1;
  justify-content: center;
}

.center-projects {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.info-grid {
  max-width: 90%;
}

.info-text {
  font-family: 'Coda', cursive;
  font-size: calc(5px + 2vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
  text-align: center;
  margin: 10px;
}

.link-text {
  font-family: 'Coda', cursive;
  font-size: calc(5px + 3vmin);
  color: rgb(0, 234, 255);
  text-shadow: 0 0 10px #000;
  text-align: center;
  margin: 10px;
}

.link-text:hover {
  color: #61dafb;
  scale: 1.1;
  cursor: pointer;
  text-decoration: underline;
}


.icon:hover {
  cursor: pointer;
  color: #61dafb;
  scale: 1.1;
}

.git-page {
  text-align: center;
  justify-content: center;
  color: #fff;
}

.git-page > h1{
  font-family: 'Coda', cursive;
  font-size: calc(5px + 4vmin);
  color: #fff;
  text-shadow: 0 0 10px #000;
  text-align: center;
  margin: 10px;
}

.git-page:hover {
  color: #61dafb;
  scale: 1.2;
  cursor: pointer;
}

.git-page:hover > h1 {
  color: #61dafb;
}

.notice-me {
  color: rgb(255, 130, 130);
}

.font-link {
  font-family: 'Coda', cursive;
}





