.main {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}

.center {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.full-height {
    /* height: 100vh; */
    flex: 1;
}